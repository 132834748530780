.pdf-viewer {
  padding: 1.25rem; /* 20px */
  max-width: 1200px;
  margin: 0 auto;
}

.controls {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.pagination button {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.pagination button:hover:not(:disabled),
.pagination button:focus:not(:disabled) {
  background-color: #f0f0f0;
  transform: translateY(-2px);
}
.content {
  border: 1px solid #ccc;
  padding: 10px;
}
.textViewer {
  width: 100%;
  height: 600px;
  position: relative;
}

.textContent {
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
}

.textPage {
  min-height: 600px; /* Roughly one page height */
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.textPage h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.textPage pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.textNavigation {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scrollLegend {
  margin-left: 10px;
}

.scrollBar {
  width: 200px;
}
.textContainer {
  width: 100%;
  height: 600px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
}

.textContent {
  white-space: pre-wrap; /* Preserves whitespace and wraps text */
  word-wrap: break-word; /* Breaks long words */
  font-family: monospace; /* Better for raw text */
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.error {
  color: red;
  text-align: center;
  padding: 20px;
}
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pageSelect {
  padding: 5px;
  border-radius: 4px;
}
.controls-row {
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #f0f0f0;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 600px) {
  .controls {
    flex-direction: column;
    gap: 10px;
  }
  .pagination {
    flex-direction: column;
    gap: 5px;
  }
}

